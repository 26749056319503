


















































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import Tree2 from "../components/tree2.vue";
import RenwuEdit from "../../components/renwu-edit.vue";
import {
  PostBookContent,
  DelConnect,
  GetBookMulu,
  createRenwu,
} from "@/request/storehouse";
import { PostYuanwen } from "@/request/check";
import {
  GetBiaozhuyuan,
  GetCategory,
  GetRenwuOption,
  AddRenwu,
} from "@/request/mark";
@Component({
  components: {
    MainHeader,
    Empty,
    Tree2,
    RenwuEdit,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private id: any = "";
  private ifShowBiaozhu: any = false;
  private ifBiaozhu: any = false;
  private biaozhuFilter: any = {
    专题类型: "医案",
  };
  private renwus: any = [];
  private fenleis: any = [];
  private biaozhuyuans: any = [];
  private bookData: any = {};
  private showBiaozhu: any = false;
  private name: any = "";
  private current: any = {};
  private data: any = {};
  private tijiaoData: any = {
    select: [],
    text: "",
  };
  private ifShowSaveConfirm: any = false;
  private ifShowTijiao: any = false;
  private toPath: any = "";
  private saveType: any = "";
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get isElse() {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  private addRenwu() {
    if (!this.biaozhuFilter["切分方式"]) {
      this.$message.warning("请选择切分方式");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] != "自定义" &&
      !this.biaozhuFilter["任务名称"]
    ) {
      this.$message.warning("请输入任务名称");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] == "按章节" &&
      this.biaozhuFilter["章节"].length == 0
    ) {
      this.$message.warning("请选择章节");
      return;
    }
    if (this.biaozhuFilter["切分方式"] == "自定义") {
      this.$message.warning("请选择内容创建标注任务");
      this.ifBiaozhu = true;
      this.ifShowBiaozhu = false;
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      专题类型: "医案",
      原文库: "古籍",
      book_id: this.id,
      切分方式: this.biaozhuFilter["切分方式"],
      任务名称: this.biaozhuFilter["任务名称"],
      分类: this.biaozhuFilter["分类"],
      标注者id: this.biaozhuFilter["标注员"],
      book_content_ids: this.biaozhuFilter["章节"],
    };
    createRenwu(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("新增成功");
        this.ifShowBiaozhu = false;
        this.getData(this.current);
      })
      .catch(() => {
        loading.close();
      });
  }
  private openBiaozhu() {
    this.biaozhuFilter = {
      专题类型: "医案",
    };
    this.getNav();
    this.ifShowBiaozhu = true;
  }
  private force() {
    this.$forceUpdate();
  }
  private preview(url: any) {
    window.open(url);
  }
  private delImg(item: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        data: {
          title_id: this.current._id,
          file_id: item.file_id,
        },
      };
      DelConnect(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.getData(this.current);
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private remoteNameMethod(val: any) {
    this.biaozhuFilter["任务名称"] = val;
    this.biaozhuFilter.id = "";
    const params: any = {
      params: {
        search: val,
      },
    };
    GetRenwuOption(this, params).then((res: any) => {
      this.renwus = res;
    });
  }
  private nameChange(e: any) {
    this.biaozhuFilter["任务名称"] = e.name;
    this.biaozhuFilter.id = e._id;
    this.biaozhuFilter["标注员"] = e.user_id;
    this.biaozhuFilter["分类"] = e.category;
    this.$forceUpdate();
  }
  private remoteFenleiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  private remoteBiaozhuyuanMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private remoteZhangjieMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private input(e: any) {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index === -1) {
      this.tijiaoData.select.push("其它");
    }
  }
  private addTijiao() {
    if (this.ifCompose) {
      this.$message.warning("请先保存当前内容！");
      return;
    }
    this.saveType = "提交";
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private saveTijiao() {
    this.saveType = "离开提交";
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private tijiao() {
    if (this.tijiaoData.select.length == 0) {
      this.$message.warning("请先选择本次修改的内容再提交");
      return;
    }
    const index = this.tijiaoData.select.findIndex((v: any) => {
      return v === "其它";
    });
    let result: any = [];
    if (index === -1) {
      result = this.tijiaoData.select;
    } else {
      if (this.tijiaoData.text) {
        result = this.tijiaoData.select;
        result[index] = "其它，" + this.tijiaoData.text;
      } else {
        result = this.tijiaoData.select;
      }
    }
    const params: any = {
      title_id: this.data.title_id,
      status: "提交",
      text: result.join("、"),
      content: this.data.content,
      章节名: this.data["章节名"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostYuanwen(this, params)
      .then((res: any) => {
        loading.close();
        this.$message.success("提交成功！");
        this.ifShowTijiao = false;
        (this.$refs.nav as any).getNav();

        // if (this.saveType == "提交") {
        //   this.back();
        // } else {
        //   this.goDefault();
        // }
      })
      .catch(() => {
        loading.close();
      });
  }
  // 跳转目标页面
  private goDefault() {
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: this.toPath,
    });
  }
  private back() {
    this.$store.commit("updateIfCompose", false);
    this.$router.back();
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.toPath = to.fullPath;
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private getData(e: any) {
    this.current = e;
    const params: any = {
      params: {
        title_id: this.current._id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostBookContent(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private save() {
    const params: any = {
      title_id: this.data.title_id,
      status: "保存草稿",
      content: this.data.content,
      章节名: this.data["章节名"],
    };
    PostYuanwen(this, params).then((res: any) => {
      this.$message.success("保存草稿成功！");
      this.$store.commit("updateIfCompose", false);
      (this.$refs.nav as any).getNav();
      // this.getData(this.current);
    });
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          book_id: this.id,
          need_status: true,
          status: "已校验",
        },
      };
      GetBookMulu(this, params, loading).then((res: any) => {
        loading.close();
        this.bookData = res;
        resolve(res);
      });
    });
  }
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
  }
}
