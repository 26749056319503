
































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetDaohang,
  AddDaohang,
  EditDaohang,
  DelDaohang,
  SortDaohang,
} from "@/request/schema";
import {
  GetBookMulu,
  DropChapter,
  EditChapter,
  DelChapter,
} from "@/request/storehouse";
@Component({})
export default class Name extends Vue {
  @Prop()
  private detail: any;
  private id: any = "";
  private currentNode: any = {};
  private expandedNode: any = [];
  private data: any = [];
  private defaultProps: any = {
    label: "章节名",
  };
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifShowAdd: any = false;
  private clickData: any = {}; // 点击的数据
  private add(data: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
      name: data.name,
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
    });
  }
  private addSure() {
    if (!this.modifyData["章节名"]) {
      this.$message.warning("请输入目录名称！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditChapter(this, this.modifyData, loading).then((res: any) => {
      loading.close();
      this.ifShowAdd = false;
      if (this.modifyData.title_id) {
        this.$message.success("重命名成功！");
        this.getNav(true);
      } else {
        this.$message.success("添加成功！");
        this.getNav();
      }
    });
  }
  private append(data: any) {
    if (!data._id) {
      this.modifyData = {
        book_id: this.id,
        章节名: "",
      };
    } else {
      this.modifyData = {
        book_id: this.id,
        parent_id: this.detail.title_id,
        章节名: "",
      };
    }
    this.ifShowAdd = true;
  }
  private handleCommand(e: any) {
    if (e.command == "重命名") {
      this.modifyData = this.detail;
      this.ifShowAdd = true;
    } else if (e.command == "删除") {
      this.$confirm(
        "您确定要删除该目录么？删除后该目录下的子结构也将全部删除。",
        "删除",
        {
          customClass: "commonConfirm",
        }
      )
        .then(() => {
          const params: any = {
            data: {
              title_id: e.data._id,
            },
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          DelChapter(this, params, loading).then((res: any) => {
            loading.close();
            this.$message.success("删除成功！");
            if (e.data._id == this.currentNode._id) {
              this.currentNode = {};
            }
            this.getNav();
          });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      book_id: this.id,
      titles: this.data.titles,
    };
    DropChapter(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getNav();
    });
  }
  private handleNodeClick(data: any) {
    this.currentNode = data;
    this.init();
  }
  private init() {
    setTimeout(() => {
      if (this.$refs.tree) {
        (this.$refs.tree as any).setCurrentKey(this.currentNode);
        this.expandedNode = [this.currentNode["_id"]];
        this.$emit("refresh", this.currentNode);
      }
    }, 100);
  }
  private getNav(ifChange?: any) {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          book_id: this.id,
          need_status: true,
        },
      };
      GetBookMulu(this, params, loading).then((res: any) => {
        loading.close();
        this.data = res;
        if (this.data.titles && this.data.titles.length > 0) {
          if (!this.currentNode._id) {
            this.currentNode = this.data.titles[0];
            this.init();
          } else {
            if (ifChange) {
              this.data.titles.forEach((ele: any) => {
                if (ele._id == this.currentNode._id) {
                  this.currentNode = ele;
                }
              });
            } else {
              this.init();
            }
          }
        }
        resolve(res);
      });
    });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getNav();
    }
  }
}
